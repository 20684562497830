import React from "react";
import { graphql } from "gatsby";
import { ImLocation } from "react-icons/im";
import { BsTelephoneFill, BsLinkedin, BsFacebook } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
export default function ContactPage() {
  return (
    <>
      <Helmet>
        <title>Contact Us - Brown Construction Services</title>
      </Helmet>
      <div className="pt-[70px] flex flex-col items-center justify-center h-full">
        <motion.h1
          initial={{ x: -10, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
              type: "ease",
            },
          }}
          className="z-10 my-10 text-navy text-5xl "
        >
          Contact Us
        </motion.h1>
        <div className="container flex flex-col md:flex-row py-20 px-5 md:px-20 mx-20">
          <div className="flex flex-col flex-grow">
            <h1 className="text-brown mb-4 text-3xl">Get in Touch</h1>
            <p className="text-navy text-lg mb-4">
              <ImLocation className="inline mr-2 text-lg" />
              7202 Poplar Street, Suite F{" "}
              <p className="ml-6">Annandale, VA 22003 </p>
            </p>
            <a
              className="text-navy text-lg mb-4 hover:text-navyHover"
              href="tel:7038138901"
            >
              <BsTelephoneFill className="inline mr-2"></BsTelephoneFill>(703)
              813-8901{" "}
            </a>
            <a
              href="mailto:info@bcservices-inc.com"
              className="text-navy hover:text-navyHover text-lg mb-4"
            >
              <GrMail className="inline mr-2" />
              info@bcservices-inc.com
            </a>
          </div>
          <div className="flex w-60 h-auto shrink-0 mx-10">
            <img
              src="/img/logo-color.jpg"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
